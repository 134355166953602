

import React, { Component } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { disclosurePackageLinkCopied } from '@uc/analytics-definitions';
import { Select } from 'antd';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import analytics from 'src/analytics';
import CopyInput from 'src/components/common/copy-input';
import SharingLegal from 'src/components/common/sharing-legal';
import { updateSharePacketLinkShareMode } from 'src/models/transactions/intents';

const clsPrefix = 'app-share-packet-link-form';

const SHARE_LINK_OPTIONS = [
  {
    value: 'LINK_SHARE_DISABLED',
    label: 'Link sharing disabled',
  },
  {
    value: 'LINK_SHARE_MANUAL_APPROVE',
    label: 'Agent must approve access before viewers can view',
  },
  {
    value: 'LINK_SHARE_AUTO_APPROVE',
    label: 'Viewers must enter name, email, and phone to access',
  },
  {
    value: 'LINK_SHARE_QUICK_SHARE',
    label: 'Anyone with this link can view',
  },
];

const LINKS_SHARE_ACTIVE_MODES = [
  'LINK_SHARE_QUICK_SHARE',
  'LINK_SHARE_AUTO_APPROVE',
  'LINK_SHARE_MANUAL_APPROVE',
];

@inject('transactions', 'ui', 'sharedPackets')
@observer
export default class SharePacketLinkForm extends Component {
  static propTypes = {
    transactions: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    sharedPackets: PropTypes.object.isRequired,
    sharePacket: PropTypes.object.isRequired,
    className: PropTypes.string,
  };

  updateSharePacketLinkShareModeCall = ({
    linkShareMode,
    notifyVisitorAccess,
  }) => {
    return this.props.transactions.dispatch(
      this.props.sharePacket.transId,
      updateSharePacketLinkShareMode({
        sharePacketId: this.props.sharePacket.id,
        linkShareMode,
        notifyVisitorAccess,
      })
    );
  };

  handleLinkShareModeChanged = async (linkShareMode) => {
    const { ui, sharedPackets, sharePacket } = this.props;
    const oldLinkShareMode = sharePacket.linkShareMode;
    const notifyVisitorAccess =
      !sharePacket.data.sharePacket.disableNotifyVisitorAccess;
    try {
      await this.updateSharePacketLinkShareModeCall({
        linkShareMode,
        notifyVisitorAccess,
      });
      ui.toast({
        message: {
          LINK_SHARE_QUICK_SHARE:
            'Seller Disclosures can now be viewed by anyone who has the link.',
          LINK_SHARE_AUTO_APPROVE:
            'Seller Disclosures can now be viewed by anyone who has the link after entering contact information.',
          LINK_SHARE_MANUAL_APPROVE:
            'Requests to view Seller Disclosures will be sent to you for approval.',
          LINK_SHARE_DISABLED: 'Link share is now off.',
        }[linkShareMode],
        type: 'success',
      });
      if (
        LINKS_SHARE_ACTIVE_MODES.includes(oldLinkShareMode) &&
        linkShareMode === 'LINK_SHARE_DISABLED' &&
        sharePacket.outEdges.some((edge) => edge.kind === 'ITEM_SHARED_AS') &&
        sharePacket.isSharingEnabled
      ) {
        ui.confirm({
          title: 'Disable sharing with existing viewers?',
          content: `Even after turning off link sharing, viewers you have previously
            shared with will continue to have access unless you disable sharing`,
          okText: 'Disable Sharing',
          okType: 'danger',
          cancelText: 'No',
          onOk: () => {
            sharedPackets.updateAccessStatus(sharePacket, 'DISABLED');
          },
        });
      }
    } catch (err) {
      ui.wentWrong(err);
    }
  };

  handleCopy = async () => {
    const { ui, sharePacket } = this.props;

    if (!this.isSharingEnabled()) {
      this.handleLinkShareModeChanged('LINK_SHARE_AUTO_APPROVE');
    }

    analytics().track('Disclosure Packet Sharing - Link Copied', {
      compass: ui.isEmbedded,
      linkShareMode: sharePacket.linkShareMode,
    });
    disclosurePackageLinkCopied({
      product: 'documents',
      sub_product: 'guided_disclosures',
    });
  };

  isSharingEnabled = () => {
    return (
      this.props.sharePacket.linkShareMode !== 'LINK_SHARE_DISABLED' &&
      this.props.sharePacket.linkShareMode !== 'LINK_SHARE_NEVER_ENABLED'
    );
  };

  render() {
    const { className, sharePacket } = this.props;
    const linkShareMode = sharePacket.linkShareMode;
    const sharingEnabled = this.isSharingEnabled();
    const urlValue = sharingEnabled
      ? sharePacket.requestAccessUrl
      : 'Select a sharing option below to enable the link';
    return (
      <div className={classNames(clsPrefix, className)}>
        <CopyInput
          value={urlValue}
          className={`${clsPrefix}__copy-input`}
          copyCallback={this.handleCopy}
          disabled={!sharingEnabled}
        />
        <Select
          size="large"
          value={
            linkShareMode === 'LINK_SHARE_NEVER_ENABLED'
              ? 'LINK_SHARE_DISABLED'
              : linkShareMode
          }
          className={`${clsPrefix}__share-mode-select`}
          onChange={this.handleLinkShareModeChanged}
          disabled={!sharePacket.isSharingEnabled}
        >
          {SHARE_LINK_OPTIONS.map(({ value, label }) => (
            <Select.Option
              className={`${clsPrefix}__option`}
              title={label}
              key={value}
              value={value}
            >
              <SettingOutlined
                style={{
                  marginRight: 8,
                }}
              />
              {label}
            </Select.Option>
          ))}
        </Select>
        {sharingEnabled && (
          <div className="margin-top">
            <SharingLegal />
          </div>
        )}
      </div>
    );
  }
}
